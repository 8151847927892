import { getLayerCornerRadius } from './getLayerCornerRadius';
import { getFontStyle } from './getFontStyle';
import { getLineHeightValue } from './getLineHeightValue';
import { getLineHeightUnit } from './getLineHeightUnit';
import { getArcDataStartingAngle } from './getArcDataStartingAngle';
import { getArcDataEndingAngle } from './getArcDataEndingAngle';
import { getArcDataInnerRadius } from './getArcDataInnerRadius';
import { getComponentPropertyDefinitions } from './getComponentPropertyDefinitions';
import { getLayerLayoutGrids } from './getLayerLayoutGrids';
import { getComponentVariantProperties } from './getComponentVariantProperties';
import { getComponentProperties } from './getComponentProperties';

export const getLayerProperties = ({ layer }) => {
  switch (layer.type) {
    case 'RECTANGLE': {
      return [
        {
          key: 'cornerRadius',
          value: getLayerCornerRadius(layer),
        },
      ];
    }
    case 'TEXT': {
      return [
        {
          key: 'characters',
          value: layer.characters,
        },
        {
          key: 'paragraphIndent',
          value: layer.style.paragraphIndent || 0,
        },
        {
          key: 'paragraphSpacing',
          value: layer.style.paragraphSpacing || 0,
        },
        {
          key: 'hangingList',
          value: layer.style.hangingList || false,
        },
        {
          key: 'hangingPunctuation',
          value: layer.style.hangingPunctuation || false,
        },
        {
          key: 'listSpacing',
          value: layer.style.listSpacing || 0,
        },
        {
          key: 'fontName',
          value: {
            family: layer.style.fontFamily,
            style: getFontStyle({
              fontWeight: layer.style.fontWeight,
              fontFamily: layer.style.fontFamily,
              italic: layer.style.italic || false,
            }),
          },
        },
        {
          key: 'fontSize',
          value: layer.style.fontSize,
        },
        {
          key: 'letterSpacing',
          value: layer.style.letterSpacing,
        },
        {
          key: 'lineHeight',
          value:
            getLineHeightUnit(layer.style) === 'AUTO'
              ? {
                  unit: 'AUTO',
                }
              : {
                  unit: getLineHeightUnit(layer.style),
                  value: getLineHeightValue(layer.style),
                },
        },
        {
          key: 'textAlignHorizontal',
          value: layer.style.textAlignHorizontal,
        },
        {
          key: 'textAlignVertical',
          value: layer.style.textAlignVertical,
        },
        {
          key: 'textAutoResize',
          value: layer.style.textAutoResize || 'NONE',
        },
        {
          key: 'textCase',
          value: layer.style.textCase || 'ORIGINAL',
        },
        {
          key: 'textDecoration',
          value: layer.style.textDecoration || 'NONE',
        },
        {
          key: 'textTruncation',
          value: layer.style.textTruncation || 'DISABLED',
        },
        {
          key: 'leadingTrim',
          value: layer.style.leadingTrim || 'NONE',
        },
        {
          key: 'listOptions',
          value: [],
        },
        {
          key: 'textOptions',
          value: [],
        },
      ];
    }
    case 'STAR': {
      return [
        {
          key: 'cornerRadius',
          value: getLayerCornerRadius(layer),
        },
        {
          key: 'innerRadius',
          value: '',
        },
        {
          key: 'pointCount',
          value: '',
        },
      ];
    }
    case 'REGULAR_POLYGON': {
      return [
        {
          key: 'cornerRadius',
          value: getLayerCornerRadius(layer),
        },
        {
          key: 'pointCount',
          value: '',
        },
      ];
    }
    case 'LINE': {
      return [];
    }
    case 'ELLIPSE': {
      return [
        {
          key: 'cornerRadius',
          value: getLayerCornerRadius(layer),
        },
        {
          key: 'arcDataStartingAngle',
          value: getArcDataStartingAngle(layer),
        },
        {
          key: 'arcDataEndingAngle',
          value: getArcDataEndingAngle(layer),
        },
        {
          key: 'arcDataInnerRadius',
          value: getArcDataInnerRadius(layer),
        },
      ];
    }
    case 'FRAME': {
      const baseProps = [
        {
          key: 'cornerRadius',
          value: getLayerCornerRadius(layer),
        },
        {
          key: 'clipsContent',
          value: layer.clipsContent || false,
        },
        {
          key: 'layoutGrids',
          value: getLayerLayoutGrids(layer),
        },
        {
          key: 'layoutMode',
          value: layer.layoutMode || null,
        },
      ];

      if (layer.layoutMode) {
        return [
          ...baseProps,
          { key: 'layoutWrap', value: layer.layoutWrap || null },
          {
            key: 'itemSpacing',
            value: layer.itemSpacing || 0,
          },
          {
            key: 'counterAxisSpacing',
            value: layer.counterAxisSpacing || 0,
          },
          {
            key: 'paddingLeft',
            value: layer.paddingLeft || 0,
          },
          {
            key: 'paddingRight',
            value: layer.paddingRight || 0,
          },
          {
            key: 'paddingTop',
            value: layer.paddingTop || 0,
          },
          {
            key: 'paddingBottom',
            value: layer.paddingBottom || 0,
          },
          {
            key: 'counterAxisAlignItems',
            value: layer.counterAxisAlignItems || 'MIN',
          },
          {
            key: 'counterAxisSizingMode',
            value: layer.counterAxisSizingMode || 'AUTO',
          },
          {
            key: 'primaryAxisAlignItems',
            value: layer.primaryAxisAlignItems || 'MIN',
          },
          {
            key: 'primaryAxisSizingMode',
            value: layer.primaryAxisSizingMode || 'AUTO',
          },
          {
            key: 'strokesIncludedInLayout',
            value: layer.strokesIncludedInLayout || false,
          },
          {
            key: 'itemReverseZIndex',
            value: layer.itemReverseZIndex || false,
          },
          {
            key: 'layoutPositioning',
            value: layer.layoutPositioning || null,
          },
        ];
      }

      return baseProps;
    }
    case 'GROUP': {
      return [];
    }
    case 'COMPONENT_SET': {
      return [
        {
          key: 'cornerRadius',
          value: getLayerCornerRadius(layer),
        },
        {
          key: 'componentPropertyDefinitions',
          value: getComponentPropertyDefinitions(layer),
        },
        {
          key: 'clipsContent',
          value: layer.clipsContent || false,
        },
        {
          key: 'layoutGrids',
          value: getLayerLayoutGrids(layer),
        },
      ];
    }
    case 'COMPONENT': {
      const baseProps = [
        {
          key: 'cornerRadius',
          value: getLayerCornerRadius(layer),
        },
        {
          key: 'clipsContent',
          value: layer.clipsContent || false,
        },
        {
          key: 'variantProperties',
          value: [], // FIXME
        },
        {
          key: 'layoutGrids',
          value: getLayerLayoutGrids(layer),
        },
        {
          key: 'layoutMode',
          value: layer.layoutMode || null,
        },
      ];

      if (layer.layoutMode) {
        return [
          ...baseProps,
          { key: 'layoutWrap', value: layer.layoutWrap || null },
          {
            key: 'itemSpacing',
            value: layer.itemSpacing || 0,
          },
          {
            key: 'counterAxisSpacing',
            value: layer.counterAxisSpacing || 0,
          },
          {
            key: 'paddingLeft',
            value: layer.paddingLeft || 0,
          },
          {
            key: 'paddingRight',
            value: layer.paddingRight || 0,
          },
          {
            key: 'paddingTop',
            value: layer.paddingTop || 0,
          },
          {
            key: 'paddingBottom',
            value: layer.paddingBottom || 0,
          },
          {
            key: 'counterAxisAlignItems',
            value: layer.counterAxisAlignItems || 'MIN',
          },
          {
            key: 'counterAxisSizingMode',
            value: layer.counterAxisSizingMode || 'AUTO',
          },
          {
            key: 'primaryAxisAlignItems',
            value: layer.primaryAxisAlignItems || 'MIN',
          },
          {
            key: 'primaryAxisSizingMode',
            value: layer.primaryAxisSizingMode || 'AUTO',
          },
          {
            key: 'strokesIncludedInLayout',
            value: layer.strokesIncludedInLayout || false,
          },
          {
            key: 'itemReverseZIndex',
            value: layer.itemReverseZIndex || false,
          },
          {
            key: 'layoutPositioning',
            value: layer.layoutPositioning || null,
          },
        ];
      }

      return baseProps;
    }
    case 'INSTANCE': {
      const baseProps = [
        {
          key: 'cornerRadius',
          value: getLayerCornerRadius(layer),
        },
        {
          key: 'clipsContent',
          value: layer.clipsContent || false,
        },
        {
          key: 'mainComponent',
          value: null,
        },
        {
          key: 'masterComponent',
          value: null,
        },
        {
          key: 'componentProperties',
          value: [], // FIXME
        },
        {
          key: 'layoutGrids',
          value: getLayerLayoutGrids(layer),
        },
        {
          key: 'layoutMode',
          value: layer.layoutMode || null,
        },
      ];

      if (layer.layoutMode) {
        return [
          ...baseProps,
          { key: 'layoutWrap', value: layer.layoutWrap || null },
          {
            key: 'itemSpacing',
            value: layer.itemSpacing || 0,
          },
          {
            key: 'counterAxisSpacing',
            value: layer.counterAxisSpacing || 0,
          },
          {
            key: 'paddingLeft',
            value: layer.paddingLeft || 0,
          },
          {
            key: 'paddingRight',
            value: layer.paddingRight || 0,
          },
          {
            key: 'paddingTop',
            value: layer.paddingTop || 0,
          },
          {
            key: 'paddingBottom',
            value: layer.paddingBottom || 0,
          },
          {
            key: 'counterAxisAlignItems',
            value: layer.counterAxisAlignItems || 'MIN',
          },
          {
            key: 'counterAxisSizingMode',
            value: layer.counterAxisSizingMode || 'AUTO',
          },
          {
            key: 'primaryAxisAlignItems',
            value: layer.primaryAxisAlignItems || 'MIN',
          },
          {
            key: 'primaryAxisSizingMode',
            value: layer.primaryAxisSizingMode || 'AUTO',
          },
          {
            key: 'strokesIncludedInLayout',
            value: layer.strokesIncludedInLayout || false,
          },
          {
            key: 'itemReverseZIndex',
            value: layer.itemReverseZIndex || false,
          },
          {
            key: 'layoutPositioning',
            value: layer.layoutPositioning || null,
          },
        ];
      }

      return baseProps;
    }
    case 'VECTOR': {
      return [
        {
          key: 'cornerRadius',
          value: getLayerCornerRadius(layer),
        },
        {
          key: 'vectorPaths',
          value: layer.vectorPaths || [],
        },
      ];
    }
    case 'BOOLEAN_OPERATION': {
      return [
        {
          key: 'cornerRadius',
          value: getLayerCornerRadius(layer),
        },
        {
          key: 'booleanOperation',
          value: layer.booleanOperation || null,
        },
      ];
    }
    case 'SECTION': {
      return [];
    }
  }
};

export const categories = [
  {
    key: 'boundingBox',
    title: 'Bounding box',
    variant: 'BOUNDING_BOX',
    properties: [
      'x',
      'y',
      'width',
      'height',
      'layoutSizingHorizontal',
      'layoutSizingVertical',
      'cornerRadius',
      'rotation',
      'pointCount',
      'innerRadius',
      'arcDataStartingAngle',
      'arcDataEndingAngle',
      'arcDataInnerRadius',
      'clipsContent',
      'parent',
      'booleanOperation',
      'vectorPaths',
    ],
  },
  {
    key: 'component',
    title: 'Component',
    variant: 'COMPONENT',
    properties: [
      'componentProperties',
      'componentPropertyReferences',
      'componentPropertyDefinitions',
      'variantProperties',
      'mainComponent',
      'masterComponent',
    ],
  },
  {
    key: 'componentInstance',
    title: 'Component Instance',
    variant: 'COMPONENT_INSTANCE',
    properties: ['mainComponent', 'masterComponent'],
  },
  {
    key: 'autoLayout',
    title: 'Auto layout',
    variant: 'AUTO_LAYOUT',
    properties: [
      'layoutWrap',
      'layoutMode',
      'itemSpacing',
      'counterAxisSpacing',
      'counterAxisAlignItems',
      'primaryAxisAlignItems',
      'paddingLeft',
      'paddingRight',
      'paddingTop',
      'paddingBottom',
      'strokesIncludedInLayout',
      'itemReverseZIndex',
    ],
  },
  {
    key: 'constraints',
    title: 'Constraints',
    variant: 'CONSTRAINTS',
    properties: ['constraints'],
  },
  {
    key: 'layoutGrid',
    title: 'Layout grid',
    variant: 'LAYOUT_GRID',
    properties: ['layoutGrids'],
  },
  {
    key: 'layer',
    title: 'Layer',
    variant: 'LAYER',
    properties: ['blendMode', 'opacity', 'layerOrder'],
  },
  {
    key: 'text',
    title: 'Text',
    variant: 'TEXT',
    properties: [
      'characters',
      'paragraphIndent',
      'paragraphSpacing',
      'hangingList',
      'hangingPunctuation',
      'listSpacing',
      'fontName',
      'fontSize',
      'letterSpacing',
      'lineHeight',
      'textAlignHorizontal',
      'textAlignVertical',
      'textAutoResize',
      'textCase',
      'textDecoration',
      'textTruncation',
      'leadingTrim',
      'listOptions',
      'textOptions',
    ],
  },
  {
    key: 'fill',
    title: 'Fill',
    variant: 'FILL',
    properties: ['fills'],
  },
  {
    key: 'stroke',
    title: 'Stroke',
    variant: 'STROKE',
    properties: [
      'strokes',
      // 'strokeAlign',
      // 'strokeWeight',
      // 'strokeStyle',
      // 'dashPattern',
      // 'strokeCap',
      // 'strokeJoin',
      // 'strokeMiterAngle',
    ],
  },
  {
    key: 'effects',
    title: 'Effects',
    variant: 'EFFECTS',
    properties: ['effects'],
  },
  {
    key: 'styles',
    title: 'Styles',
    variant: 'STYLES',
    properties: ['styles'],
  },
];

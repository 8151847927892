import { categories } from './DesignSection.config';

export const getCategories = ({ properties, layerType }) => {
  let nextCategories = [...categories];

  const parent = properties.find((property) => property.key === 'parent');

  if (
    !parent ||
    !parent.value ||
    (parent.value.type !== 'FRAME' &&
      parent.value.type !== 'COMPONENT_SET' &&
      parent.value.type !== 'COMPONENT' &&
      parent.value.type !== 'INSTANCE' &&
      parent.value.type !== 'GROUP' &&
      parent.value.type !== 'BOOLEAN_OPERATION')
  ) {
    nextCategories = nextCategories.filter(
      (category) => category.variant !== 'CONSTRAINTS',
    );
  }

  if (layerType !== 'TEXT') {
    nextCategories = nextCategories.filter(
      (category) => category.variant !== 'TEXT',
    );
  }

  if (
    !(
      layerType === 'FRAME' ||
      layerType === 'COMPONENT' ||
      layerType === 'INSTANCE'
    )
  ) {
    nextCategories = nextCategories.filter(
      (category) => category.variant !== 'AUTO_LAYOUT',
    );
  }

  if (
    layerType !== 'FRAME' &&
    layerType !== 'COMPONENT_SET' &&
    layerType !== 'COMPONENT' &&
    layerType !== 'INSTANCE'
  ) {
    nextCategories = nextCategories.filter(
      (category) => category.variant !== 'LAYOUT_GRID',
    );
  }

  if (
    layerType !== 'COMPONENT_SET' &&
    layerType !== 'COMPONENT' &&
    layerType !== 'INSTANCE' &&
    parent &&
    parent.value &&
    parent.value.type !== 'COMPONENT' &&
    parent.value.type !== 'INSTANCE'
  ) {
    nextCategories = nextCategories.filter(
      (category) => category.variant !== 'COMPONENT',
    );
  }

  if (layerType !== 'COMPONENT' || layerType !== 'COMPONENT_SET') {
    nextCategories = nextCategories.filter(
      (category) => category.variant !== 'COMPONENT',
    );
  }

  if (layerType !== 'INSTANCE') {
    nextCategories = nextCategories.filter(
      (category) => category.variant !== 'COMPONENT_INSTANCE',
    );
  }

  if (
    layerType !== 'COMPONENT_SET' &&
    layerType !== 'COMPONENT' &&
    layerType !== 'INSTANCE' &&
    (!parent || !parent.value)
  ) {
    // ONLY FOR ROOT FRAME
    nextCategories = nextCategories.filter(
      (category) => category.variant !== 'COMPONENT',
    );
  }

  switch (layerType) {
    case 'FRAME': {
      return nextCategories;
    }
    case 'GROUP': {
      return nextCategories
        .filter((category) => category.variant !== 'FILL')
        .filter((category) => category.variant !== 'STROKE')
        .filter((category) => category.variant !== 'CONSTRAINTS');
    }
    case 'RECTANGLE':
    case 'ELLIPSE':
    case 'LINE':
    case 'STAR':
    case 'REGULAR_POLYGON':
    case 'TEXT':
    case 'BOOLEAN_OPERATION':
    case 'COMPONENT':
    case 'COMPONENT_SET':
    case 'INSTANCE':
    case 'VECTOR': {
      return nextCategories;
    }
    default: {
      return [];
    }
  }
};
